// @ts-nocheck
import { useCallback, useMemo } from "react";
import { AnyObjectSchema, object } from "yup";
import {TranslateFunc} from '@/hooks/useTranslate';

export const SELECTOR_FORM_FIELD_ERROR = "form-field-error";

const gotoErrorField = () => {
  setTimeout(() => {
    const elError = document.querySelector(`.${SELECTOR_FORM_FIELD_ERROR}`);

    if (elError) {
      elError.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, 200);
};

interface UseValidation {
  scheme: AnyObjectSchema;
  requiredFields?: {
    [key: string]: boolean | { message: string };
  };
  translate?: TranslateFunc;
}

const useValidation = ({ scheme, requiredFields = {}, translate = (text) => text }: UseValidation) => {
  const schemaData = useMemo(() => {
    const data = { ...scheme };
    Object.keys(data).forEach((field) => {
      if (!data[field]) {
        return;
      }

      try {
        if (typeof data[field].clone === "function") {
          if (requiredFields[field]) {
            const message =
              typeof requiredFields[field] === "object"
                ? requiredFields[field].message
                : translate("This field is required");

            data[field] = data[field].clone().required(message);
          } else {
            data[field] = data[field].clone();
          }
        } else {
          console.error(
            "Not defined or incorrect schema for field",
            field,
            data[field]
          );
        }
      } catch (err) {
        console.error("Error field clone", err);
      }
    });
    return object().shape(data);
  }, [requiredFields, scheme, translate]);

  const setAsyncError = useCallback((helper, error) => {
    if (typeof error === "object") {
      Object.keys(error).forEach((key) => {
        const text = Array.isArray(error[key])
          ? error[key].join("\n")
          : `${error[key]}`;

        helper.setFieldError(key, translate(text));
        helper.setFieldTouched(key, true, false);
      });
    }

    gotoErrorField();
  }, []);

  return {
    scheme: schemaData,
    setAsyncError,
    gotoErrorField,
  };
};

export default useValidation;
