// @ts-nocheck
import { useMemo } from "react";
import { UseInterface } from "types/modules/interface-types";
import Waiting from "@/components/basic/Waiting";
import dynamic from "next/dynamic";
import useClientContext from "@/context/ClientContext";

const SubscriptionSuccess = dynamic(() => import("../components/SubscriptionSuccess"), {
  ssr: false,
  suspense: true,
});

const SubscriptionConfirm = dynamic(() => import("../components/SubscriptionConfirm"), {
  ssr: false,
  suspense: true,
});

const useActions = () => {
  const { openModal } = useClientContext();
  return useMemo(() => {
    return {
      openSuccessSubscription: () => {
        openModal(
          () => {
            return (
              <Waiting>
                <SubscriptionSuccess />
              </Waiting>
            );
          },
          { smallPadding: true }
        );
      },
      openConfirmSubscription: () => {
        openModal(
          () => {
            return (
              <Waiting>
                <SubscriptionConfirm />
              </Waiting>
            );
          },
          { smallPadding: true }
        );
      },
    };
  }, [openModal]);
};

export default useActions;
