"use client";
import useClientContext from "@/context/ClientContext";
import { useCommonSWR } from "@/libs/swr";
import apiNotifications from "@/modules/auth/constants/apiNotifications";
import {
  IApiCustomerNotificationTypes,
  IApiNotification,
  IApiNotificationsCounters,
} from "@/types/interfaces/notifications";
import { prepareQueryParamsToString } from "@/utils/url";

const ONE_MINUTE_MS = 60 * 1000;

export const useDataCustomerNotifications = (type?: IApiCustomerNotificationTypes) => {
  const { isAuthorized } = useClientContext();

  let notification_type;
  let is_favorite;
  if (type) {
    if (type === IApiCustomerNotificationTypes.FAVORITES) {
      is_favorite = "1";
    } else {
      notification_type = type;
    }
  }

  const filterStr = prepareQueryParamsToString({notification_type, is_favorite });

  return useCommonSWR<IApiNotification[]>({
    key: apiNotifications.notifications() + `${filterStr ? `?${filterStr}` : ""}`,
    stop: !isAuthorized,
    defaultData: [],
    withPagination: true,
    accumulate: true,
  });
};

export const useDataCustomerNotification = (id: number | string) => {
  const { isAuthorized } = useClientContext();

  return useCommonSWR<IApiNotification | null>({
    key: apiNotifications.notification(id),
    stop: !isAuthorized,
  });
};

export const useDataCustomerNotificationsCounters = () => {
  const { isAuthorized } = useClientContext();

  return useCommonSWR<IApiNotificationsCounters>({
    key: apiNotifications.counters(),
    stop: !isAuthorized,
    defaultData: {
      all: 0,
      promo: 0,
      sale: 0,
      order: 0,
      finance: 0,
      platform: 0,
      favorites: 0,
    },
  });
};

export const useDataCustomerNotificationsUnread = () => {
  const { isAuthorized } = useClientContext();

  return useCommonSWR<number>({
    key: apiNotifications.totalCount(),
    stop: !isAuthorized,
    defaultData: 0,
    options: { refreshInterval: ONE_MINUTE_MS },
  });
};
