import { useSearchParams } from "next/navigation";
import { useMemo } from "react";

const useQuery = () => {
  const searchParams = useSearchParams();

  const query = useMemo(() => {
    const temp: any = {};

    searchParams?.forEach((value, key) => {
      temp[key] = value;
    });

    return temp;
  }, [searchParams]);

  return query;
};

export default useQuery;
