"use client";
import React, {useCallback, useRef, useState} from "react";
import dynamic from "next/dynamic";
import PublicIcons from "@/components/basic/PublicIcon";
import useClientContext from "@/context/ClientContext";

import styles from "./styles.module.scss";

const HomePageDrawerMenu = dynamic(() => import("../drawerMenu/MobileHomeDrawerMenu"), {
  ssr: false,
});
const PopupCategories = dynamic(() => import("../PopupCategories"), {
  ssr: false,
});

const ButtonCatalogue = () => {
  const { translate, isMobileSize } = useClientContext();
  const [show, setShow] = useState(false);
  const [hovered, setHovered] = useState(false);
  const buttonRef = useRef<any>(null);

  //not used now, is needed to add opened state to menu button
  const toggleMenu = useCallback((event: any) => {
    setShow((prev) => {
      return !prev;
    });
  }, []);

  //not used now, is needed to add opened state to menu button
  const hideMenu = useCallback((event: any) => {
    if(event.target !== buttonRef.current && !buttonRef.current?.contains(event.target)) {
      setShow(false);
    }
  }, []);

  return (
    <>
      <div className={styles.buttonCatalogue} onClick={() => setShow(true)} onMouseOver={() => setHovered(true)} ref={buttonRef}>
        {/*{*/}
        {/*  show ? (*/}
        {/*    <PublicIcons name="close2" width={24} height={24} className={styles.icon} />*/}
        {/*  ) : (*/}
        {/*    <PublicIcons name="hamburger" width={24} height={24} className={styles.icon} />*/}
        {/*  )*/}
        {/*}*/}
        <PublicIcons name="hamburger" width={24} height={24} className={styles.icon} />
        <div className={styles.name}>{translate("All categories")}</div>
      </div>
      {isMobileSize ? (
        <HomePageDrawerMenu hidden={!show} onHide={() => setShow(false)} />
      ) : (
        <PopupCategories hovered={hovered} hidden={!show} onHide={() => setShow(false)} />
      )}
    </>
  );
};

export default ButtonCatalogue;
