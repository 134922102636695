import cn from "@/libs/cn";
import { useField } from "formik";
import { SELECTOR_FORM_FIELD_ERROR } from "@/hooks/useValidation";

import stylesForms from "@/styles/forms.module.scss";
import styles from "./styles.module.scss";
import {ReactNode} from 'react';

interface ValidationErrorMessageProps {
  name: string;
  hint?: string | ReactNode;
  hideHint?: boolean;
  externalError?: string;
  hideIfEmpty?: boolean;
}

const ValidationErrorMessage = (props: ValidationErrorMessageProps) => {
  const { name, hint, hideHint, externalError, hideIfEmpty } = props;
  const [, meta] = useField({ name });

  const { error, touched } = meta;

  const errors = [error, externalError].filter(i => !!i).join(' ');

  const textError = errors || hint || "";
  const isShowError = externalError || (error && touched);

  if (hideIfEmpty && !(isShowError ? textError : hint)) {
    return null;
  }

  return (
    <div className={styles.ValidationErrorMessage}>
      <div
        className={cn(
          !hideHint && stylesForms.basicHint,
          isShowError && stylesForms.basicHint,
          isShowError && stylesForms.basicHintError,
          isShowError && SELECTOR_FORM_FIELD_ERROR
        )}
      >
        {isShowError ? textError : hint}
      </div>
    </div>
  );
};

export default ValidationErrorMessage;
