export enum IApiCustomerNotificationChannels {
  EMAIL = "email",
}

export interface IApiNotificationsCounters {
  all: number;
  promo: number;
  sale: number;
  order: number;
  finance: number;
  platform: number;
  favorites: number;
}

export enum IApiCustomerNotificationTypes {
  PROMO = "promo",
  SALE = "sale",
  ORDER = "order",
  FINANCE = "finance",
  PLATFORM = "platform",
  FAVORITES = "favorite",
}

export enum IApiCustomerNotificationSettingsTypes {
  MARKETING = "marketing",
  NEWS = "news",
}

export interface IApiNotification {
  id: number;
  created: string;
  subject: string;
  content: string;
  notification_type: IApiCustomerNotificationTypes;
  is_read: boolean;
  is_favorite: boolean;
}
