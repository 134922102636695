"use client";
import styles from "./styles.module.scss";
import FooterSubscribe from '@/modules/layout/components/Footer/FooterSubscribe';
import FooterList from '@/modules/layout/components/Footer/FooterList';
import FooterSocials from '@/modules/layout/components/Footer/FooterSocials';
import {IApiStaticContent} from '@/types/interfaces/static-content';

interface FooterProps {
  staticPages?: IApiStaticContent[];
}

const Footer = ({ staticPages }: FooterProps) => {
  return (
    <footer className={styles.Footer}>
      <FooterSubscribe />
      <FooterList pages={staticPages} />
      <div className={styles.footerSeparator} />
      <FooterSocials />
    </footer>
  );
};

export default Footer;
