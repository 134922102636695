import useClientContext from '@/context/ClientContext';
import React, {useCallback, useState} from 'react';
import {Form, Formik} from 'formik';
import FieldTextInput from '@/controls/fields/FieldTextInput';
import ButtonSubmit from '@/controls/ButtonSubmit';
import styles from "../styles.module.scss";
import useValidation from '@/hooks/useValidation';
import subscribeFormScheme from '@/modules/layout/components/Footer/forms/validation';
import publicRequests from '@/modules/public/requests';
import useActions from '@/modules/public/hooks/useActions';

const initValues = {
  email: "",
};

const requiredFields = {
  email: true,
};

const FormSubscribe = () => {
  const { translate, toast } = useClientContext();
  const [loading, setLoading] = useState(false);
  const { openConfirmSubscription } = useActions();

  const { scheme, setAsyncError } = useValidation({
    scheme: subscribeFormScheme({ translate }),
    requiredFields,
    translate,
  });

  const handleSubmit = useCallback(
    async (values: any, helpers: any) => {
      setLoading(true);
      publicRequests.subscribeToRecommendations(values)
        .then(() => {
          helpers.resetForm();
          openConfirmSubscription();
        })
        .catch((error) => {
          if (error?.detail) {
            toast.error(error?.detail);
          }
          if (error?.email) {
            toast.error(error?.email);
          }
        })
        .finally(() => setLoading(false));
    },
    [openConfirmSubscription, toast]
  );

  return (
    <div className={styles.formSubscribe}>
      <Formik
        initialValues={initValues}
        onSubmit={handleSubmit}
        validationSchema={scheme}
        validateOnBlur={false}
      >
        <Form className={styles.formSubscribe}>
          <FieldTextInput
            name="email"
            placeholder={translate("Your email")}
            hideHint
            classNameWrap={styles.subscribeInput}
            autoComplete="off"
          />
          <ButtonSubmit loading={loading} className={styles.subscribeBtn}>
            {translate("Subscribe")}
          </ButtonSubmit>
        </Form>
      </Formik>
    </div>
  );
}

export default FormSubscribe;
