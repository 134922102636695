import React from "react";
import cn from "@/libs/cn";
import PublicIcons from "@/components/basic/PublicIcon";
import Image from "../basic/Image";

import styles from "./styles.module.scss";

interface Avatar {
  src?: string | null;
  width?: any;
  height?: any;
  className?: string;
  classNameImage?: string;
}

const Avatar = ({ src, width, height, className, classNameImage }: Avatar) => (
  <div
    className={cn(styles.avatar, className)}
    style={{
      width,
      height,
    }}
  >
    {src ? (
      <Image src={src} alt="avatar" className={cn(styles.image, classNameImage)} width={width} height={height} />
    ) : (
      <PublicIcons
        name="userGray"
        width={width}
        height={height}
        objectFit="fill"
        className={cn(styles.icon, classNameImage)}
      />
    )}
  </div>
);

export default Avatar;
