import { IApiCustomerNotificationTypes } from "@/types/interfaces/notifications";

export const baseRoute = "profile";

const routesProfile = {
  profile: () => `/${baseRoute}/`,
  profileOld: () => `/${baseRoute}/old/`,
  addresses: () => `/${baseRoute}/addresses/`,
  favorites: () => `/${baseRoute}/favorites/`,
  notifications: (tab?: IApiCustomerNotificationTypes) => `/${baseRoute}/notifications/${tab ? `?tab=${tab}` : ""}`,
  orders: () => `/${baseRoute}/orders/`,
  order: (orderId: any) => `/${baseRoute}/order/?id=${orderId}`,
  payments: () => `/${baseRoute}/payments/`,
  settings: () => `/${baseRoute}/settings/`,
};

export default routesProfile;
