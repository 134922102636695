import Link from "@/components/basic/Link";
import cn from "@/libs/cn";
import React from "react";

import styles from "./styles.module.scss";
interface ButtonRich {
  className?: string;
  classNameLabel?: string;
  isActive?: boolean;
  label: string;
  icon: (props: any) => JSX.Element;
  count?: number;
  color: "red" | "green" | "primary";
  href: string;
}

const ButtonRich = ({
  className,
  classNameLabel,
  isActive,
  label,
  icon: Icon,
  count = 0,
  color,
  href,
}: ButtonRich) => {
  return (
    <Link className={cn(styles.ButtonRich, className)} href={href}>
      <div className={styles.wrapIcon}>
        <Icon
          width={28}
          className={cn(styles.icon, isActive && styles.active)}
        />
        {!!count && (
          <div
            className={cn(
              styles.count,
              color === "red" && styles.red,
              color === "green" && styles.green,
              color === "primary" && styles.primary
            )}
          >
            {count}
          </div>
        )}
      </div>
      <div className={cn(styles.label, isActive && styles.label_active, classNameLabel)}>
        {label}
      </div>
    </Link>
  );
};

export default ButtonRich;
