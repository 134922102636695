"use client";
import React, { useCallback, useMemo } from "react";
import PublicIcons from "@/components/basic/PublicIcon";
import useClientContext from "@/context/ClientContext";
import cn from "@/libs/cn";
import { useDataPublicCurrencies } from "@/modules/public/hooks/useData";
import Select from "@/controls/Select";

import styles from "./styles.module.scss";

interface SelectCurrencyProps {}

const SelectCurrency = (props: SelectCurrencyProps) => {
  const { currency } = useClientContext();
  const currencies = useDataPublicCurrencies();

  const options = useMemo(() => currencies.data.map((i) => ({ value: i.code, label: i.name })), [currencies.data]);

  const onChange = useCallback(() => {}, []);

  const value = currency.value;

  return (
    <Select className={styles.SelectCurrency} options={options} onChange={onChange}>
      <>
        <div className={styles.value}>{value}</div>
        <PublicIcons name="arrowCaretDown" height={18} width={18} data-arrow="icon-arrow" />
      </>
    </Select>
  );
};

export default SelectCurrency;
