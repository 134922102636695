"use client";
import PublicIcons from '@/components/basic/PublicIcon';
import React, {useMemo} from 'react';
import styles from './styles.module.scss';
import cn from "@/libs/cn";
import {useDataPublicPhoneNumbers} from '@/modules/public/hooks/useData';
import useClientContext from '@/context/ClientContext';

interface PhoneNumbersBarProps {
  className?: string;
}

const OPERATORS = ['kyivstar', 'vodafone', 'lifecell'];

function formatPhoneNumber(phoneNumber: string) {
  const cleanNumber = phoneNumber.replace("+38", "");
  return cleanNumber.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, "($1) $2-$3-$4");
}

const PhoneNumbersBar = ({ className }: PhoneNumbersBarProps) => {
  const { locale } = useClientContext();
  const phoneNumbers = useDataPublicPhoneNumbers(locale);
  const phoneNumbersObj = useMemo(() => {
    return phoneNumbers.data.reduce((result, phoneNumber) => {
      result[phoneNumber.operator] = phoneNumber.phone_number;
      return result;
    }, {} as any);
  }, [phoneNumbers.data]);
  const phoneNumbersComponents = useMemo(() => {
    return OPERATORS.map((operator) => {
      if(phoneNumbersObj[operator]) {
        const phoneNumber = phoneNumbersObj[operator];
        const displayPhoneNumber = formatPhoneNumber(phoneNumber);
        return (
          <a key={operator} href={`tel:${phoneNumber}`} className={styles.PhoneNumber}>
            <PublicIcons
              name={operator as any}
              width={20}
              height={20}
            />
            {displayPhoneNumber}
          </a>
        )
      } else {
        return null;
      }
    }).filter((elem) => !!elem);
  }, [phoneNumbersObj]);
  return (
    <div className={cn(styles.PhoneNumbersBar, className)}>
      {phoneNumbersComponents}
    </div>
  )
}

export default PhoneNumbersBar;
