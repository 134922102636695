import React, { ChangeEvent, ReactNode, useCallback, useEffect, useState } from "react";
import { useField } from "formik";
import cn from "@/libs/cn";
import useThrottle from "@/hooks/useThrottle";
import TextInput, { PropsTextInput } from "@/controls/TextInput";
import ValidationErrorMessage from "../ValidationErrorMessage";

import stylesForms from "@/styles/forms.module.scss";

const MIN_COUNT_LETTERS_FOR_VALIDATION = 6;
const MAX_COUNT_LETTERS_FOR_VALIDATION = 12;
const DELAY_ASYNC_VALIDATION = 500;

export interface PropsFieldTextInput extends PropsTextInput {
  name: string;
  initValue?: any;
  asyncCheck?: (value: string) => Promise<true | string>;
  setAsyncError?: (name: string, value: any) => void;
  externalError?: string;
  hint?: string | ReactNode;
  hideHint?: boolean;
  hideError?: boolean;
  fieldClassName?: string;
  autoFocus?: boolean;
  showInputPrompt?: boolean;
  inputPrompt?: string;
  inputPromptClassName?: string;
  disabled?: boolean;
  onClick?: () => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  minHeight?: any;
  showCharsCounter?: boolean;
  maxCharacters?: number;
  charsCounterClassName?: string;
  hideErrorIfEmpty?: boolean;
}

const FieldTextInput = (props: PropsFieldTextInput) => {
  const {
    name,
    initValue,
    asyncCheck,
    setAsyncError = () => {},
    externalError,
    hint,
    hideHint,
    hideError,
    onChange: onChangeOut = () => {},
    fieldClassName,
    minHeight,
    type,
    pattern,
    onClick,
    inputPrompt,
    showInputPrompt = false,
    inputPromptClassName,
    autoComplete,
    showCharsCounter = false,
    maxCharacters = 0,
    charsCounterClassName,
    hideErrorIfEmpty,
    ...rest
  } = props;

  const validate = useCallback(
    (value: any) => {
      if (!pattern) {
        return;
      }

      const regPattern = new RegExp(pattern);

      if (type === "url" && !regPattern.test(value)) {
        return `Incorrect url, example ${pattern}`;
      }
    },
    [pattern, type]
  );

  const [field, meta, helpers] = useField({
    name,
    validate: pattern ? validate : undefined,
  });

  const { error, touched } = meta;
  const isShowError = !!externalError || !!(error && touched);

  const [checking, setChecking] = useState(false);
  const throttle = useThrottle(DELAY_ASYNC_VALIDATION);

  const onAsyncValidation = useCallback(
    async (val?: string) => {
      if (
        !asyncCheck ||
        initValue === val ||
        !val ||
        val.length < MIN_COUNT_LETTERS_FOR_VALIDATION ||
        val.length > MAX_COUNT_LETTERS_FOR_VALIDATION
      ) {
        return;
      }
      setChecking(true);
      const response = await asyncCheck(val);
      setChecking(false);

      if (typeof response === "string") {
        setAsyncError(name, response);
      } else {
        setAsyncError(name, "");
      }
    },
    [asyncCheck, initValue, name, setAsyncError]
  );

  useEffect(() => {
    throttle(() => onAsyncValidation(field.value), name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.value, name]);

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      field.onChange(e);
      onChangeOut(e);
    },
    [field, onChangeOut]
  );

  return (
    <div className={cn(stylesForms.basicWrap, showCharsCounter && stylesForms.charsCounterWrapper, fieldClassName)} onClick={onClick}>
      <TextInput
        {...rest}
        type={type}
        {...field}
        onChange={onChange}
        minHeight={minHeight}
        autoComplete={autoComplete || name}
        loading={checking}
        error={isShowError}
        showCharsCounter={showCharsCounter}
        charsCounterClassName={charsCounterClassName}
        charsCount={field.value?.length || 0}
        maxCharacters={maxCharacters}
      />
      {showInputPrompt && <div className={cn(stylesForms.inputPrompt, inputPromptClassName)}>{inputPrompt}</div>}
      {!hideError && (
        <ValidationErrorMessage name={name} hint={hint} hideHint={hideHint} externalError={externalError} hideIfEmpty={hideErrorIfEmpty}/>
      )}
    </div>
  );
};

export default FieldTextInput;
