import styles from './styles.module.scss';
import {ReactNode, useEffect, useState} from 'react';
import cn from 'classnames';

interface TooltipProps {
  children: ReactNode;
  className?: string;
  classNameWrapper?: string;
  text: string;
  disableTooltip?: boolean;
  position?: 'top' | 'bottom';
  type?: 'showOnHover' | 'hideOnClick';
  asTag?: 'span' | 'div';
}

const Tooltip = (props: TooltipProps) => {

  const {
    children,
    className,
    classNameWrapper,
    text,
    disableTooltip = false,
    position = 'bottom',
    type = 'showOnHover',
    asTag = 'div',
  } = props;

  const [show, setShow] = useState(() => type !== 'showOnHover');
  const Tag = asTag;

  return (
    <Tag
      className={cn(styles.TooltipWrapper, classNameWrapper)}
      onMouseEnter={() => type === 'showOnHover' && !disableTooltip && setShow(true)}
      onMouseLeave={() => type === 'showOnHover' && !disableTooltip && setShow(false)}
    >
      {children}
      {
        !disableTooltip && !!text && show && (
          <div
            className={cn(styles.Tooltip, styles[position], className)}
            onClick={() => type === 'hideOnClick' && setShow(false)}
          >
            {text}
          </div>
        )
      }
    </Tag>
  )
};

export default Tooltip;
