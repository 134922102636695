"use client";
import React from "react";
import PublicIcons from "@/components/basic/PublicIcon";
import useClientContext from "@/context/ClientContext";
import ButtonRich from "../ButtonRich";
import { useDataFavoritesIdsOfProducts, useDataFavoritesIdsOfStores } from "@/modules/profile/hooks/useData";
import routesProfile from "@/modules/profile/constants/routesProfile";

const Icon = () => <PublicIcons name="heartOutlineGrey" width={28} height={28} />;

interface ButtonFavorites {
  className?: string;
  classNameLabel?: string;
}

const ButtonFavorites = ({ className, classNameLabel }: ButtonFavorites) => {
  const { translate } = useClientContext();
  const products = useDataFavoritesIdsOfProducts();
  const stores = useDataFavoritesIdsOfStores();

  return (
    <ButtonRich
      className={className}
      classNameLabel={classNameLabel}
      label={translate("Favourites")}
      icon={Icon}
      color={"primary"}
      href={routesProfile.favorites()}
      count={products.data.length + stores.data.length}
    />
  );
};

export default ButtonFavorites;
