import BlockMaxWidth from '@/modules/layout/components/BlockMaxWidth';
import styles from "./styles.module.scss";
import LinkServer from '@/components/basic/Link/LinkServer';
import routesSeller from '@/modules/seller/constants/routesSeller';
import routesHome, {
  ABOUT_NAME_PAGE, BUY_IN_CREDIT_NAME_PAGE,
  CONTACTS_NAME_PAGE, DELIVERY_NAME_PAGE, FAQ_NAME_PAGE, GIFT_CARD_NAME_PAGE,
  JOB_NAME_PAGE,
  NEWS_NAME_PAGE, PAYMENT_IN_PARTS_NAME_PAGE, PRIVACY_NAME_PAGE, TERMS_NAME_PAGE, WARRANTY_NAME_PAGE
} from '@/modules/home/constants/routesHome';
import {useCallback, useMemo, useState} from 'react';
import useClientContext from '@/context/ClientContext';
import PublicIcons from '@/components/basic/PublicIcon';
import cn from "@/libs/cn";
import {MARKETPLACE_NAME} from '@/constants/info';
import Tooltip from '@/components/Tooltip';
import {TranslateFunc} from '@/hooks/useTranslate';
import {IApiStaticContent} from '@/types/interfaces/static-content';
import useClientRouter from '@/hooks/useClientRouter';
import {openWindowTab} from '@/utils/window';

interface LinkListProps {
  item: {
    title: string;
    href: string;
    page?: IApiStaticContent | null;
    isStaticPage: boolean;
    hideForIndexing?: boolean;
  };
  locale: string;
  translate: TranslateFunc;
  isMobileSize: boolean;
}

const ListLink = (props: LinkListProps) => {
  const router = useClientRouter();
  const {
    item, locale, translate, isMobileSize,
  } = props;
  const isActive = useMemo(() => {
    if(!!item?.page) {
      return item.page.is_active;
    }
    return true;
  }, [item.page]);
  return (
    <>
      {
        isActive ? (
          <>
            {
              item.hideForIndexing ? (
                <div className={styles.menuItem}
                  onClick={() => {
                    openWindowTab(item.href, isMobileSize ? '_self' : '_blank', locale);
                  }}
                >
                  {item.title}
                </div>
              ) : (
                <LinkServer locale={locale} className={styles.menuItem} href={item.href} target={isMobileSize ? undefined : "_blank"}>
                  {item.title}
                </LinkServer>
              )
            }
          </>
        ) : (
          <div className={styles.menuItem}>
            <Tooltip
              text={translate("Page will be ready soon")}
              position="top"
              classNameWrapper={styles.menuItemTooltipWrapper}
              disableTooltip={isMobileSize}
            >
            {item.title}
            </Tooltip>
          </div>
        )
      }
    </>
  )
}

interface FooterListProps {
  pages?: IApiStaticContent[];
}

const FooterList = ({ pages = []}: FooterListProps) => {
  const { translate, locale, isMobileSize } = useClientContext();
  // const { data: pages } = useDataPublicStaticPages();
  const [openedIndexes, setOpenedIndexes] = useState<number[]>([]);
  const menuLinks = useMemo(() => {
    return [
      {
        title: MARKETPLACE_NAME,
        items: [
          {
            title: translate("About us"),
            href: routesHome.about(),
            page: pages.find((page) => page.slug === ABOUT_NAME_PAGE),
            isStaticPage: true,
          },
          {
            title: translate("Contacts"),
            href: routesHome.contacts(),
            page: pages.find((page) => page.slug === CONTACTS_NAME_PAGE),
            isStaticPage: true,
          },
          {
            title: translate("News"),
            href: routesHome.news(),
            page: pages.find((page) => page.slug === NEWS_NAME_PAGE),
            isStaticPage: true,
          },
          {
            title: translate("Job"),
            href: routesHome.job(),
            page: pages.find((page) => page.slug === JOB_NAME_PAGE),
            isStaticPage: true,
          }
        ]
      },
      {
        title: translate("Information"),
        items: [
          {
            title: translate("Delivery & Payment"),
            href: routesHome.delivery(),
            page: pages.find((page) => page.slug === DELIVERY_NAME_PAGE),
            isStaticPage: true,
          },
          {
            title: translate("Warranty"),
            href: routesHome.warranty(),
            page: pages.find((page) => page.slug === WARRANTY_NAME_PAGE),
            isStaticPage: true,
          },
          {
            title: translate("FAQ"),
            href: routesHome.faq(),
            page: pages.find((page) => page.slug === FAQ_NAME_PAGE),
            isStaticPage: true,
          },
          {
            title: translate("Terms & Conditions"),
            href: routesHome.term(),
            page: pages.find((page) => page.slug === TERMS_NAME_PAGE),
            isStaticPage: true,
          },
          {
            title: translate("Privacy Policy"),
            href: routesHome.privacy(),
            page: pages.find((page) => page.slug === PRIVACY_NAME_PAGE),
            isStaticPage: true,
          }
        ]
      },
      /*{
        title: translate("Benefit"),
        items: [
          {
            title: translate("Buy in credit"),
            href: routesHome.buyInCredit(),
            page: pages.find((page) => page.slug === BUY_IN_CREDIT_NAME_PAGE),
            isStaticPage: true,
          },
          {
            title: translate("Payment in parts"),
            href: routesHome.paymentInParts(),
            page: pages.find((page) => page.slug === PAYMENT_IN_PARTS_NAME_PAGE),
            isStaticPage: true,
          },
          {
            title: translate("Gift card"),
            href: routesHome.giftCard(),
            page: pages.find((page) => page.slug === GIFT_CARD_NAME_PAGE),
            isStaticPage: true,
          },
        ]
      },
      {
        title: translate("Business"),
        items: [
          {
            title: `${MARKETPLACE_NAME} ${translate("Marketplace")}`,
            href: routesHome.home(),
            page: null,
            isStaticPage: false,
          },
          {
            title: translate("Become a Seller"),
            href: `${routesSeller.landing()}?lang=${locale}`,
            page: null,
            isStaticPage: false,
            hideForIndexing: true,
          },
          {
            title: translate("Seller workspace"),
            href: `${routesSeller.landing()}?lang=${locale}&openLogin=true`,
            page: null,
            isStaticPage: false,
            hideForIndexing: true,
          },
        ]
      },*/
    ]
  }, [locale, pages, translate]);

  const toggleMenuBlock = useCallback((index: number) => {
    if(openedIndexes.includes(index)) {
      setOpenedIndexes(prev => prev.filter(item => item !== index));
    } else {
      setOpenedIndexes(prev => [...prev, index]);
    }
  }, [openedIndexes]);

  return (
    <BlockMaxWidth className={styles.footerMenu}>
      {
        menuLinks.map((menuBlock, index) => (
          <div key={menuBlock.title} className={styles.menuBlock}>
            <div className={styles.menuBlockTitle} onClick={() => isMobileSize && toggleMenuBlock(index)}>
              <div>{menuBlock.title}</div>
              <PublicIcons name={"arrowDownGrey"} width={24} height={24} className={cn(styles.arrow, openedIndexes.includes(index) ? styles.opened : styles.closed)} />
            </div>
            <div className={cn(styles.menuBlockItems, isMobileSize && (openedIndexes.includes(index) ? styles.opened : styles.closed))}>
              {menuBlock.items.map((item) => {
                const isVisible = !item.isStaticPage || (item.isStaticPage && !!item?.page && item.page.is_active);

                if (!isVisible) {
                  return null;
                }

                return (
                <ListLink key={item.title} translate={translate} locale={locale} item={item} isMobileSize={isMobileSize}/>
              )})}
            </div>
            <div className={styles.menuBlockSeparator} />
          </div>
        ))
      }
    </BlockMaxWidth>
  )
}

export default FooterList;
