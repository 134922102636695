import cn from "@/libs/cn";
import React from "react";
import styles from "./styles.module.scss";
import './map.css';

/*
  Need to copy:
    sprite.png to /assets/countries/sprite.png
*/

const BASE_HEIGTH = 16;
const BASE_WIDTH = 20;

interface ImageFlag {
  code: string;
  height?: number;
}

const ImageFlag = ({ code, height = BASE_HEIGTH }: ImageFlag) => {
  const K = height / BASE_HEIGTH;

  const style = {
    height: `${BASE_HEIGTH}px`,
    width: `${BASE_WIDTH}px`,
    minWidth: `${BASE_WIDTH}px`,
    transform: `scale(${K})`,
  };

  return (
    <div
      className={cn(
        styles.imageFlag,
        "country_flag",
        `country_${code?.toLowerCase() || "other"}`
        // className
      )}
      style={style}
    />
  );
};

export default ImageFlag;
