import styles from "./styles.module.scss";

export const CONTENT_SELECTOR_ID = "content";

interface ContentProps {
  children: any;
}

const Content = ({ children }: ContentProps) => {
  return (
    <div id={CONTENT_SELECTOR_ID} className={styles.Content}>
      {children}
    </div>
  );
};

export default Content;
