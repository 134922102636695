"use client";

import { useEffect } from "react";
import { AnalyticsEventDetail, AnalyticsEventType } from "./AnalyticsConstants";

interface AnalyticsEvent extends Event {
  detail: AnalyticsEventDetail;
}

function analyticsCallback(e: AnalyticsEvent) {
  const { event_name, ...rest } = e.detail;
  console.log("analyticsCallback", event_name, rest);
  // @ts-ignore
  if (typeof gtag === "function") {
    // @ts-ignore
    gtag("event", event_name, rest);
  }
}

const AnalyticsObserver = () => {
  useEffect(() => {
    // @ts-ignore
    document.addEventListener(AnalyticsEventType, analyticsCallback);
    return () => {
      // @ts-ignore
      document.removeEventListener(AnalyticsEventType, analyticsCallback);
    };
  }, []);

  return null;
};

export default AnalyticsObserver;
