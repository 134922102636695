import { string } from "yup";
import { regExpEmail } from "@/utils/regexp";
import { TranslateFunc } from "@/hooks/useTranslate";

const subscribeFormScheme = ({ translate = (text) => text }: { translate: TranslateFunc }) => {
  const scheme = {
    email: string()
      .email(translate("Please enter a valid email address"))
      .matches(regExpEmail, translate("Please enter a valid email address")),
  };

  return scheme as any;
};

export default subscribeFormScheme;
