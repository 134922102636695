import styles from "./styles.module.scss";
import BlockMaxWidth from '@/modules/layout/components/BlockMaxWidth';
import useClientContext from '@/context/ClientContext';
import LinkServer from '@/components/basic/Link/LinkServer';
import {
  MARKETPLACE_ANDROID,
  MARKETPLACE_FACEBOOK,
  MARKETPLACE_INSTAGRAM, MARKETPLACE_IOS, MARKETPLACE_NAME,
  MARKETPLACE_TELEGRAM,
  MARKETPLACE_TWITTER, MARKETPLACE_VIBER,
  MARKETPLACE_YOUTUBE
} from '@/constants/info';
import PublicIcons from '@/components/basic/PublicIcon';

const FooterSocials = () => {
  const { translate, locale } = useClientContext();
  const copyrightText = `© ${new Date().getFullYear()} ${MARKETPLACE_NAME}. ${translate("All rights reserved.")}`;
  return (
    <BlockMaxWidth className={styles.footerSocials}>
      <div className={styles.logoBlock}>
        <LinkServer locale={locale} href="/">
          <PublicIcons name="mallprixLogoFooter" width={165} height={40} />
        </LinkServer>
        <span className={styles.copyright}>{copyrightText}</span>
      </div>
      <div className={styles.filler}></div>
      <div className={styles.socialBlock}>
        {/*<span className={styles.infoLabel}>{translate("Follow Us in")}:</span>*/}
        <div className={styles.footerSocialsIcons}>
          <LinkServer locale={locale} href={MARKETPLACE_FACEBOOK} target="_blank">
            <PublicIcons name="facebookColor" className={styles.social} fill />
          </LinkServer>
          <LinkServer locale={locale} href={MARKETPLACE_TWITTER} target="_blank">
            <PublicIcons name="xColor" className={styles.social} fill />
          </LinkServer>
          <LinkServer locale={locale} href={MARKETPLACE_INSTAGRAM} target="_blank">
            <PublicIcons name="instagramColor" className={styles.social} fill />
          </LinkServer>
          <LinkServer locale={locale} href={MARKETPLACE_YOUTUBE} target="_blank">
            <PublicIcons name="youtubeColor" className={styles.market} fill />
          </LinkServer>
          <LinkServer locale={locale} href={MARKETPLACE_TELEGRAM} target="_blank">
            <PublicIcons name="telegramColor" className={styles.market} fill />
          </LinkServer>
          <LinkServer locale={locale} href={MARKETPLACE_VIBER} target="_blank">
            <PublicIcons name="viberColor" className={styles.market} fill />
          </LinkServer>
        </div>
      </div>
      {/*<div className={styles.socialBlock}>*/}
      {/*  <span className={styles.infoLabel}>{translate("Get our Apps")}:</span>*/}
      {/*  <div className={styles.footerSocialsIcons}>*/}
      {/*    <LinkServer locale={locale} href={MARKETPLACE_IOS} target="_blank">*/}
      {/*      <PublicIcons name="downloadIOS" width={160} height={48} />*/}
      {/*    </LinkServer>*/}
      {/*    <LinkServer locale={locale} href={MARKETPLACE_ANDROID} target="_blank">*/}
      {/*      <PublicIcons name="downloadAndroid" width={169} height={48} />*/}
      {/*    </LinkServer>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </BlockMaxWidth>
  )
}

export default FooterSocials;
