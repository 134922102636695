const API_NOTIFICATIONS = `/notifications/`;

const apiNotifications = {
  notifications: () => `${API_NOTIFICATIONS}`,
  notification: (id: number | string) => `${API_NOTIFICATIONS}${id}/`,
  counters: () => `${API_NOTIFICATIONS}counters/`,
  favoriteAdd: () => `${API_NOTIFICATIONS}favorite/add/`,
  favoriteRemove: () => `${API_NOTIFICATIONS}favorite/remove/`,
  notificationRead: () => `${API_NOTIFICATIONS}notification-read/`,
  totalCount: () => `${API_NOTIFICATIONS}total-count/`,
};

export default apiNotifications;
