"use client";
import { useEffect } from "react";

const UTM_LABELS_NAME = "utm_labels";
const EXTERNAL_PARAMS_PREFIX = "utm_";
// Url example: /?utm_source=catalog+&utm_medium=hotline&utm_campaign=hotline_list

const AnalyticsDetectExternalParams = () => {
  useEffect(() => {
    try {
      const { search } = global.location;
      const query = new URLSearchParams(search);
      const names = [...new Set(query.keys())].filter((name) => name.startsWith(EXTERNAL_PARAMS_PREFIX));
      const utms: any = {};
      if (names.length) {
        names.forEach((name) => {
          utms[name] = query.get(name);
        });
        localStorage.setItem(UTM_LABELS_NAME, JSON.stringify(utms));
      }
    } catch (err) {
      console.error("Error processing utm labels", err);
    }
  }, []);

  return null;
};

export default AnalyticsDetectExternalParams;
