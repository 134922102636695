"use client";
import { useEffect, useRef } from "react";
import useClientContext from "@/context/ClientContext";
import useQuery from "./useQuery";
import useClientRouter from "./useClientRouter";
import { MESSAGE_REDIRECT_PRODUCT_NOT_FOUND } from "@/constants/common";
import useActions from "@/modules/public/hooks/useActions";

const useProcessQueryAction = () => {
  const cont = useRef("");
  const query = useQuery();
  const router = useClientRouter();
  const { modules, toast: globalToast, translate } = useClientContext();
  const publicActions = useActions();

  useEffect(() => {
    const { pathname, href } = global?.location;

    if (cont.current !== href) {
      cont.current = href;
      // Example: ?action=auth:openNewPassword&email=ruslan.muraha21@gmail.com&token=eyJhbGciOiJIUzI1NiIsIn
      const { action, toast, ...params } = query;

      if (action) {
        // auth:openNewPassword
        const v = `${action}`.split(":");

        // @ts-ignore
        const moduleAction = modules?.[v[0]]?.actions?.[v[1]];
        if (v.length === 2 && moduleAction && typeof moduleAction === "function") {
          moduleAction(params);
        } else {
          if (v[0] === "subsription" && v[1] === "success") {
            publicActions.openSuccessSubscription();
          } else {
            console.error("Undefined action", v);
          }
        }
      }

      if (toast) {
        if (toast === MESSAGE_REDIRECT_PRODUCT_NOT_FOUND) {
          globalToast.info(translate("The product is not available but there is always a worthy replacement!"));
        } else {
          // error:Incorect token
          const v = `${toast}`.split(":");
          // @ts-ignore
          const action = globalToast?.[v[0]];
          if (v.length === 2 && action && typeof action === "function") {
            action(translate(v[1]));
          } else {
            console.error("Undefined toast", v);
          }
        }
      }

      if (action || toast) {
        router.replace(pathname);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, translate]);
};

export default useProcessQueryAction;

export const ProcessQueryAction = () => {
  useProcessQueryAction();
  return null;
};
