import routesSeller from '@/modules/seller/constants/routesSeller';

export function openWindowTab(href: string, target: '_self' | '_blank', locale?: string) {
  const path = href;
  const isSellerPath = path.startsWith(routesSeller.landing());

  let preHref = path;

  if (!preHref.startsWith("http") && !preHref.startsWith("//")) {
    preHref = isSellerPath ? path : locale ? `/${locale}${path}` : path;
    if (process.env.NODE_ENV === "production" && !preHref.startsWith("http")) {
      preHref = `${process.env.NEXT_PUBLIC_DOMAINE_STAGE}${preHref}`;
    }
  }

  if (window && "open" in window && typeof window.open === "function") {
    // @ts-ignore
    window.open(preHref, target).focus();
  }
}
