"use client";
import React from "react";
import PublicIcons from "@/components/basic/PublicIcon";
import useClientContext from "@/context/ClientContext";
import ButtonRich from "../ButtonRich";
import routesPublic from "@/modules/public/constants/routesPublic";

const Icon = () => <PublicIcons name="cartGrey" width={28} height={28} />;

interface ButtonCart {
  className?: string;
  classNameLabel?: string;
}

const ButtonCart = ({ className, classNameLabel }: ButtonCart) => {
  const {
    translate,
    modules: { cart },
  } = useClientContext();

  return (
    <ButtonRich
      className={className}
      classNameLabel={classNameLabel}
      label={translate("Cart")}
      icon={Icon}
      color={"red"}
      href={routesPublic.cart()}
      count={cart.list.length}
    />
  );
};

export default ButtonCart;
