"use client";
import React from "react";
import PublicIcons from "@/components/basic/PublicIcon";
import useClientContext from "@/context/ClientContext";
import { useDataCustomerNotificationsUnread } from "@/modules/profile/hooks/useDataNotifications";
import ButtonRich from "../ButtonRich";
import routesProfile from "@/modules/profile/constants/routesProfile";

const Icon = () => <PublicIcons name="notificationsGrey" width={28} height={28} />;

interface ButtonNotifications {
  className?: string;
  classNameLabel?: string;
}

const ButtonNotifications = ({ className, classNameLabel }: ButtonNotifications) => {
  const { translate } = useClientContext();
  const { data: count } = useDataCustomerNotificationsUnread();

  return (
    <ButtonRich
      className={className}
      classNameLabel={classNameLabel}
      label={translate("Notifications")}
      icon={Icon}
      color="red"
      href={routesProfile.notifications()}
      count={count}
    />
  );
};

export default ButtonNotifications;
